import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.css']
})
export class BlogComponent implements OnInit {

  public projects = [];
  constructor() { }

  ngOnInit() {
    this.projects.push({
      id:1,
      image:'assets/img/projects/1.jpg',
      title:'Eli-Lilly',
      sortDesc:"Eli Lilly and Company is a global pharmaceutical company headquartered in Indianapolis, We're proud to have collaborated with Eli Lilly on a groundbreaking project, leveraging the power of React and React Native. Our team engineered innovative solutions, seamlessly blending web (React) and mobile (React Native) technologies to enhance user experiences and streamline operations in the healthcare sector.",
      url:''//'project/1'
    },
    {
      id:2,
      image:'assets/img/projects/2.jpg',
      title:'Ameriprise Financial',
      sortDesc:'Ameriprise Financial is an investment company that provides clients with financial planning. In collaboration with Ameriprise Financial, we revolutionized financial technology by seamlessly integrating React, Node.js, and Behavior-Driven Development (BDD) with Playwright. This dynamic combination has not only elevated user experiences but also streamlined testing and ensured robust, reliable solutions.',
      url:''//'project/2'
    },
    {
      id:3,
      image:'assets/img/projects/3.jpg',
      title:'Liberty Mutual Insurance',
      sortDesc:'Liberty Mutual Insurance Company is an American diversified global insurer and the sixth-largest property and casualty insurer in the world. At Liberty Mutual Insurance, our collaboration ushered in a new era of tech innovation, leveraging React, React Native, Node.js, and Behavior-Driven Development (BDD) with Cucumber. This powerful amalgamation enhanced user experiences, streamlined development, and fortified applications with scenario-driven testing. ',
      url:''//'project/3'
    },
    {
      id:4,
      image:'assets/img/projects/4.jpg',
      title:'AT&T Inc',
      sortDesc:"AT&T Inc. is an American multinational telecommunications holding company. It is the world's fourth-largest telecommunications company, In collaboration with AT&T Inc, we spearheaded a technological revolution, integrating AWS, Python, React, Android, and iOS. This dynamic fusion not only propelled connectivity but also established a versatile and robust foundation for the digital future.",
      // sortDesc:'AT&T Inc. is an American multinational telecommunications corporation,AT&T is the largest provider...',
      url:''//'project/4'
    },
    {
      id:5,
      image:'assets/img/projects/5.jpg',
      title:'Argha Services, Inc',
      sortDesc:"Argha is a Provider of software development services on an ongoing engagement model and on fixed price engagement models. In partnership with Argha we pioneered excellence through the integration of .NET, React, and advanced QA Automation. This holistic approach not only ensured robust applications but also streamlined quality assurance processes for optimal performance.",
      // sortDesc:'Argha is a software company that provides a variety of services that include application development...',
      url:''//'project/5'
    },
    {
      id:6,
      image:'assets/img/projects/2.jpg',
      title:'TD Bank Mount Laurel',
      sortDesc:"In our collaboration with TD Bank, Mount Laurel, we've pioneered a transformative fusion of technology, seamlessly integrating Nest, Next.js, Android, iOS, and React Native. This comprehensive approach not only enhances digital banking experiences but also establishes a future-ready foundation for financial innovation. As an outcome we Redefined financial technology standards for TD Bank customers.",
      // sortDesc:'TD bank is one of the 10 largest banks in US. The purpose of this project was to design a web application...',
      url:''//'project/6'
    },
    {
      id:7,
      image:'assets/img/projects/7.jpg',
      title:'UNITED HEALTH CARE',
      sortDesc:'',
      url:''//'project/7'
    },
    {
      id:8,
      image:'assets/img/projects/8.jpg',
      title:'THE MONEY TREE OF GEORGIA',
      sortDesc:'',
      url:''//'project/8'
    },
    {
      id:9,
      image:'assets/img/projects/9.jpg',
      title:'Crown Fine Arts',
      sortDesc:"",
      // sortDesc:'Crown fine arts is one of the largest fine arts logistic company provide services related to Fine arts management...',
      url:''//'project/9'
    }   
    )
  }

}
